import { render, staticRenderFns } from "./inspiration-tile-v2.vue?vue&type=template&id=49bb1541&"
import script from "./inspiration-tile-v2.vue?vue&type=script&lang=js&"
export * from "./inspiration-tile-v2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\code\\dept_muuto_forntend\\master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49bb1541')) {
      api.createRecord('49bb1541', component.options)
    } else {
      api.reload('49bb1541', component.options)
    }
    module.hot.accept("./inspiration-tile-v2.vue?vue&type=template&id=49bb1541&", function () {
      api.rerender('49bb1541', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/inspiration-tile-v2/inspiration-tile-v2.vue"
export default component.exports