import { render, staticRenderFns } from "./story-slide-content.vue?vue&type=template&id=0e16ee98&"
import script from "./story-slide-content.vue?vue&type=script&lang=js&"
export * from "./story-slide-content.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\code\\dept_muuto_forntend\\master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e16ee98')) {
      api.createRecord('0e16ee98', component.options)
    } else {
      api.reload('0e16ee98', component.options)
    }
    module.hot.accept("./story-slide-content.vue?vue&type=template&id=0e16ee98&", function () {
      api.rerender('0e16ee98', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/story-slider/partials/story-slide-content/story-slide-content.vue"
export default component.exports