import { mapState, mapActions } from 'vuex';
import { rInterval } from "./../../basics/js/utils";


import SeriesOverview from "./series-overview/series-overview";
import AnimatedHero from "./animated-hero/animated-hero";
import IntroductionBlock from "./introduction-block/introduction-block";
import DesignExplorer from "./design-explorer/design-explorer";
import PropertyExplorer from "./property-explorer/property-explorer";
import InspirationGallery from "./inspiration-gallery/inspiration-gallery";
import MaterialExplorer from "./material-explorer/material-explorer";
import InspirationSlider from "./inspiration-slider/inspiration-slider";
import ScrollRotator from "./scroll-rotator/scroll-rotator";
import ModelExplorer from "./model-explorer/model-explorer";
import MaterialOverlay from "./material-overlay/material-overlay";
import ExplanationOverlay from "./explanation-overlay/explanation-overlay";
import ShortcutsBar from "./shortcuts-bar/shortcuts-bar";
import ShortcutsOverlay from "./shortcuts-overlay/shortcuts-overlay";
import ArBanner from "./ar-banner/ar-banner";
import StepGuide from '../../components/step-guide/step-guide';
import ImageProductsButton from "../image-products/image-products-button";
import StorySlider from "../story-slider/story-slider";


export default {
	name: "experience-scroll",
	components: {
		AnimatedHero,
		IntroductionBlock,
		PropertyExplorer,
		DesignExplorer,
		SeriesOverview,
		MaterialExplorer,
		MaterialOverlay,
		InspirationSlider,
		ScrollRotator,
		ModelExplorer,
		InspirationGallery,
		ExplanationOverlay,
		ShortcutsOverlay,
		ShortcutsBar,
		ArBanner,
		StepGuide,
		ImageProductsButton,
		StorySlider
	},
	data() {
		return {
			offsetTop: 0,
			oldScrollY: 0,
			resizeTimeout: 0,
			scrollPosition: 0,
			sectionProps: [],
			sections: [],
			sectionTriggerPoint: 0.4, //show section, when 40% is visible
			marginOffset: 0
		};
	},
	computed: {
		...mapState({
			bodyFixed: state => state.bodyFixed,
            windowH: state => state.windowH,
            windowW: state => state.windowW,
			triggerExperienceResize: state => state.triggerExperienceResize,
		}),
	},
	watch: {
        windowH: function() {
            this.resizeListener();
        },
        windowW: function() {
            this.resizeListener();
        },
		bodyFixed(state) {
			if (state) {
				this.sections.forEach((section) => {
					section.classList.add("is-active");
				});
			} else {
				this.scrollListener();
			}
		},
		triggerExperienceResize(newVal) {
			if (newVal === true) {
				this.resizeListener();
				this.setTriggerExperienceResize(false);
			}
		}
	},
	methods: {
		...mapActions([
            "setTriggerExperienceResize"
		]),

		resizeListener: function () {
			
			if (this.sections && this.sections.length > 0) {
				
				this.marginOffset = window.innerHeight * (1 - this.sectionTriggerPoint);
				
				this.$refs.container.style.setProperty("height", "auto");
				clearTimeout(this.resizeTimeout);
				
				this.resizeTimeout = setTimeout(() => {
					
					this.offsetTop = this.$el.offsetTop;
					// console.log(" this.offsetTop???", this.offsetTop)
					
					this.sectionProps = this.sections.map((section) => {
						return { y: section.offsetTop, h: section.offsetHeight };
					});
					
					
					// this.sectionProps[0].y += this.marginOffset;

					// console.table(this.sectionProps);
					this.scrollListener();
				}, 2000);
			}
		},
		scrollListener: function (forceUpdate) {
			if ( this.bodyFixed ) return false;


			let scroll = window.scrollY < 0 ? 0 : window.scrollY;
			
			if (this.sections && this.sections.length > 0 && (scroll === 0 || scroll != this.oldScrollY || forceUpdate)) {
				this.oldScrollY = scroll;

				this.sectionProps.forEach((props, index) => {
					let section = this.sections[index];

					let adjustedScrollPosition = scroll - this.offsetTop;
					
					// BEHAVIOR EXPLANATION:
					// A section gets active, when scrolled to its y position minus marginOffset (so, once 25% is inside the viewport) UNTIL it has left the viewport completely.
					if (adjustedScrollPosition + this.marginOffset >= props.y) {
						if (adjustedScrollPosition <= props.y + props.h) {
							//scrolled inside
							section.classList.add("is-active");
							section.classList.remove("is-past");
						} else {
							section.classList.remove("is-active");
							section.classList.add("is-past");
						}
					} else {
						//element not yet reached
						section.classList.remove("is-active");
						section.classList.remove("is-past");
					}
				});
			}
		},
		getOffsetTopRecursive: function (element) {
			var actualOffset = element.offsetTop;

			var current = element.offsetParent;
			while (current != null) {
				actualOffset += current.offsetTop;

				current = current.offsetParent;
			}

			return actualOffset;
		},
	},
	mounted() {
		this.sections = [...this.$refs.container.querySelectorAll(".section")];
		let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

		if (this.sections && this.sections.length > 0 ) {
			if ( !isIE11 ) {
				rInterval(this.scrollListener, 50);

				setTimeout(
					function () {
						this.scrollListener(true);
					}.bind(this),
					1000
				);

				this.sections[0].classList.add("is-active");
				this.$el.classList.add("is-ready");
				
			} else {
				//make all sections ready for IE11
				this.sections.forEach((section) => {
					section.classList.add("is-active");
				});
			}
		}
	},
};
